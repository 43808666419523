import React from "react"
import Layout from "../components/layout"
import Post from "../components/post"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import PaginationLinks from "../components/pagination";

const TagPosts = props => {
  const posts = props.data.allMarkdownRemark.edges
  const { tag, currentPage, numberOfTagPages, pageUrl } = props.pageContext
  //const { totalCount } = props.data.allMarkdownRemark
  
  /*const pageHeader = `Post${
    totalCount === 1 ? "" : "s"
  } on "${tag}"`
  const pageHeader = `Tagged with "${tag}"` */

  return (
    <Layout pageTitle={tag}>
      <SEO title={tag} keywords={["tags", "categories"]} />
      {posts.map(({ node }) => {
        return (
          <Post
            key={node.id}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            author={node.frontmatter.author}
            slug={node.fields.slug}
            body={node.excerpt}
            fluid={node.frontmatter.image.childImageSharp.fluid}
            tags={node.frontmatter.tags}
          />
        )
      })}
        <PaginationLinks pageUrl={pageUrl} currentPage={currentPage} numberOfPages = {numberOfTagPages}/>
      </Layout >
  )
}


export const tagQuery = graphql`
    query($tag: String!, $skip: Int!, $limit: Int!) {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { in: [$tag] } } }
        skip: $skip
        limit: $limit
        
      ) {
        totalCount
        edges {
          node {
            id
            frontmatter {
              title
              
              date(formatString: "MMMM Do YYYY")
              author
              tags
              image {
                childImageSharp {
                  fluid(maxWidth: 650) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            fields {
              slug
            }
            excerpt
          }
        }
      }
    }
  `

export default TagPosts
